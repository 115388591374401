<template>
  <vx-card title="">
    <CoreSkillsSidebarForm :isSidebarActive="addNewDataSidebar"
                           @refreshData="toggleRefreshData"
                           @closeSidebar="toggleDataSidebar"
                           :data="sidebarData"
                           :rating="rating"
                           :coreSkillsOptions="coreSkillsOptions"
                           :coreSkillFilter="coreSkillFilter"/>
    <vs-row class="core-skill-header">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="12" class="mb-lg-0 mb-3">
        <div class="vx-col w-full">
          <label><h2 class="mb-0">Rating</h2></label>
          <ul class="demo-alignment">
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="1" @change="onChangeRating($event)">
                1
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="2" @change="onChangeRating($event)">
                2
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="3" @change="onChangeRating($event)">
                3
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="4" @change="onChangeRating($event)">
                4
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="5" @change="onChangeRating($event)">
                5
              </vs-radio>
            </li>
          </ul>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12">
        <v-select :options="coreSkillsOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="coreSkillFilter" class="mb-4 md:mb-0"/>
      </vs-col>
    </vs-row>

    <div class="mt-5">

      <vs-row>
        <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-lg="9" vs-sm="8" vs-xs="12">
          <h4>Resources</h4>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="4" vs-xs="12">
          <vs-button color="primary" type="filled" @click="addNewResource" class="mt-3">Add New Resources</vs-button>
        </vs-col>
        <vs-divider/>
      </vs-row>

      <vs-row :key="index" v-for="(item,index) in coreSkillDevelopments">
        <vs-col vs-type="flex" vs-sm-justify="left" vs-xs-justify="center" vs-align="center" vs-lg="9" vs-sm="8"
                vs-xs="12" style="flex-wrap: wrap;">
          <p class="h5 font-bold" style="display: flex; flex: 0 0 100%;">{{ item.title }}</p>
          <p class="h5 mb-2" style="display: flex; flex: 0 0 100%;">{{ item.description }}</p>
          <p class="h5 mb-2" style="display: inline-block">
            <a v-if="item.link" :href="item.link" target="_blank" class="mr-5">
              <vs-button color="primary" type="filled" class="mt-3">Link</vs-button>
            </a>
            <a class="mt-5" v-if="item.document" :href="item.document" target="_blank">
              <vs-button color="primary" type="filled" class="mt-3">View
                Document
              </vs-button>
            </a>
          </p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="4" vs-xs="12">
          <a href="#" @click.stop="editResource(item)">
            <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"
            />
          </a>
          <a href="#" @click.stop="deleteResource(item)">
            <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"
            />
          </a>
        </vs-col>
        <vs-divider/>
      </vs-row>
    </div>
  </vx-card>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import CoreSkillsSidebarForm from './CoreSkillsSidebarForm.vue'
import vSelect from 'vue-select'
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'
import {mapActions} from "vuex";

export default {
  data() {
    return {
      rating: "1",
      coreSkills: {},

      coreSkillsOptions: [],
      coreSkillFilter: {},

      coreSkillDevelopments: {},
      coreSkillDevelopmentID: null,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},

      currentResource: {}
    }
  },
  watch: {
    coreSkillFilter(obj) {
      this.getCoreSkillDevelopments();
    },
  },
  methods: {
    ...mapActions("coreSkill", [
      "deleteCoreSkillDevelopment",
      "getCoreSkillDevelopment",
      "fetchCoreSkills",
      "setRating"
    ]),
    addNewResource() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    editResource(data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    deleteResource(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.acceptDeleteAlert(item)
        }
      })

    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: color
      })
    },

    acceptDeleteAlert(item) {

      const params = {
        coreSkillId: this.coreSkillFilter.value,
        rating: this.rating,
        resourceId: item._id,
      }
      this.$vs.loading()
      this.deleteCoreSkillDevelopment(params)
        .then((res) => {
          this.$vs.loading.close()
          this.coreSkillDevelopmentID = null;
          this.showMessage("Success", "Core Skill document deleted successfully.", "success");
        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        });
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    toggleRefreshData(val = false) {
      let $this = this;
      setTimeout(function () {
        $this.getCoreSkillDevelopments();
      }, 500);


    },
    onChangeRating(event) {
      this.setRating(this.rating)
      this.getCoreSkillDevelopments();
    },

    getCoreSkillDevelopments() {
      let filter = {
        coreSkill: this.coreSkillFilter.value,
        rating: this.rating,
      }

      this.$vs.loading()
      this.getCoreSkillDevelopment(filter)
        .then((res) => {
          this.$vs.loading.close()
          this.coreSkillDevelopments = this.$store.state.coreSkill.coreSkillDevelopments;
        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        });
    }
  },
  components: {
    FormWizard,
    TabContent,
    CoreSkillsSidebarForm,
    vSelect
  },
  created() {
    this.setRating(this.rating)
    this.$vs.loading()
    this.fetchCoreSkills()
      .then((res) => {
        this.$vs.loading.close()
        this.coreSkillsOptions = this.$store.state.coreSkill.coreSkillsOptions;
        this.coreSkillFilter = this.$store.state.coreSkill.coreSkillFilter;
      })
      .catch(err => {
        this.$vs.loading.close()
        console.error(err)
      });
  },

}
</script>
